.Header__bottom__brand{
    width: 80%;
    margin: auto;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
        font-weight: 400;
        font-size: 18px;
    }
}