.MemberExclusive{
    width: 100%;
    height: 265px;
    background-color: rgba(220, 225, 218, 1);
    text-align: center;
    h1{
        padding-top: 44px;
        margin-bottom: 16px;
        font-weight: normal;
        font-size: 38px;
        }
    p{
        font-weight: 400;
        font-size: 38px;
        margin-bottom: 49px;
    }
    h5{
        font-weight: 400;
        font-size: 18px;
    }
}