.Header_Bottom {
  height: 10px;
  display: flex;
  height: 90px;
  align-items: end;
  justify-content: space-around;
    width: 80%;
    margin: auto;
  .Header_Bottom_div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 195px;
    &:nth-child(2) {
      width: 220px;
    }
    &:nth-child(3) {
      width: 180px;
    }
    h3 {
      margin-top: 10px;
      color: rgba(0, 0, 0, 0.5);
      font-family: "Roboto";
      font-size: "12px";
      font-style: "normal";
      font-weight: 700;
      line-height: "normal";
    }
    .money_back_img {
      width: 22px;
      height: 29px;
    }
  }
}
