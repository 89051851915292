.Mid__cards {
  width: 1440px;
  margin: auto;
  display: flex;
  gap: 25px;
  align-items: center;
}

.Mid {
  display: flex;
  gap: 25px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 104px;

  .Mid_cards {
    height: 515px;
    overflow: hidden;
    &:hover {
      .Mid__card__hover {
        transform: translateY(350px);
      }
    }

    .Mid__card__img {
      width: 100%;
    }

    .Mid__card__bottom {
      display: flex;
      flex-direction: column;

      // .Mid__card__title {}
      .Mid {
        font-weight: 700;
        font-size: 25px;
        margin-top: 25px;
      }
    }

    .Mid__card__hover {
      width: 80%;
      background-color: #fff;
      border-radius: 35px;
      margin: auto;
      transition: 0.5s ease;
      position: relative;
      top: -650px;

      div {
        height: 50px;
        // transform: translateY(-520px);
        display: flex;
        background-color: #fff;
        align-items: center;
        width: 50%;
        margin: auto;
        justify-content: space-evenly;

        span {
          width: 2px;
          height: 30px;
          background-color: #000;
        }
      }
    }
  }
  &__card_other {
    @extend .Hover__card;
  }
}
.Mid__cards {
  width: 90%;
  margin: auto;
}
.Hover__card {
  overflow: hidden;
  height: 566px;
  margin-top: -52px;
    &2{
        height: 56px;
    }
  &:hover {
    .Hover__card__bottom {
      transform: translateY(-518px);
      transition: 0.5s ease;
      &:nth-child(2) {
        transform: translateY(-550px);
      }
    }
  }

  .Hover__card__img {
    height: 100%;
  }

  .Hover__card__bottom {
    background-color: rgba(0, 0, 0, 0.4);
    width: 406px;
    height: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    position: relative;
    transition: 0.5s ease;

    top: 27px;
    // transform: translateY(555px);
    .Hover__card__title {
      width: 150px;
      height: 28px;
      color: #fff;
      font-size: 28px;
      font-size: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: 25px;
    }

    .Hover__card__description {
      width: 210px;
      color: #fff;
      font-size: 16px;
      font-style: "normal";
      font-weight: 700;
      line-height: 22px /* 137.5% */;
      margin-left: 25px;
    }

    .Hover__card__btn {
      margin-left: 25px;
      border: 1px solid #fff;
      color: #fff;

      &:hover {
        border: none;
      }
    }
  }
}
.Hover__card__img {
  width: 100%;
  height: 100%;
}
.Hover__card2{
    @extend .Hover__card;
    margin-top: 0;
    height: 515px;
}