.Sign__up {
  width: 100%;
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: space-between;
  .right {
    display: flex;

    input {
      width: 515px;
      height: 50px;
      border: 2px solid rgba(50, 51, 52, 1);
      background-color: #fff;
      &:active, &:focus{
        border-radius: 0px;
      width: 515px;
      height: 50px;
      border: 2px solid rgba(50, 51, 52, 1);
      background-color: #fff;
      }
    }
    button {
      width: 85px;
      height: 50px;
      background-color: rgba(50, 51, 52, 1);
      border: 2px solid rgba(50, 51, 52, 1);
      color: #fff;
      .mail{
        font-size: 25px;
      }
      &:hover {
        width: 85px;
        height: 50px;
        background-color: rgba(50, 51, 52, 1);
      border: 2px solid rgba(50, 51, 52, 1);

      }
    }
  }
}
